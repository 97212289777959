@font-face {
  font-family: "MADE Outer Sans thin";
  src: url("../fonts/MADE\ Outer\ Sans\ Thin\ PERSONAL\ USE.otf");
}
@font-face {
  font-family: "MADE Outer Sans bold";
  src: url("../fonts/MADE\ Outer\ Sans\ Bold\ PERSONAL\ USE.otf");
}

.section {
  position: relative;
  top: 0;

  height: 100vh;
}

.section::before {
  position: absolute;
  content: "";
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: url("../images/gym.png")!important;

  background-size: cover;
  background-repeat: no-repeat;
  opacity: 50%;
}

.intro {
  position: absolute;
  top: 222px;
  bottom: 60px;
  left: 80px;
  z-index: 8;
  font-family: "MADE Outer Sans thin";
}

.intro span {
  font-size: 80px;
  font-weight: 300;
  line-height: 75px;
  text-transform: uppercase;
  margin-top: 8px;
}

.intro span h1 {
  font-family: "MADE Outer Sans bold";
  font-size: 80px;
  font-weight: 900;
  margin: 0;
}
.intro h2 {
  font-size: 26px;
 
  font-weight: 400;
  margin-top: 80px;
}

.intro hr {
  width: 163px;
  color: #c79e5a;
  border: 3px solid #c79e5a;
  margin-bottom: 20px;
  opacity: 1;
}

.shape {
  position: absolute;

  width: 100%;
  bottom: 0;
}

.blue {
  height: 660px;
  clip-path: polygon(0 68%, 100% 43%, 100% 100%, 0% 100%);

  background-color: #2c5364;
}

.yellow {
  height: 660px;
  clip-path: polygon(55% 64%, 93% 55%, 93% 100%, 50% 100%);

  background-color: #c79e5a;
  z-index: 999;
  margin-top: -660px;
}

.programs {
  position: absolute;
  display: flex;
  bottom: 40px;
  right: 14%;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  z-index: 999;
  padding: 8px 16px;
  border-left: 1px solid #787e69;
}

.programs-btn {
  display: block;
  background: linear-gradient(to left, transparent 50%, #cdd4de 50%);
  background-size: 200%;
  background-position: right;
  color: #2b2e35;
  font-weight: 600;
  border: 0;
  outline: 0;
  text-align: start;

  transition: background-position 0.5s ease-in-out;
}

.programs-btn:hover {
  background-position: left;
}

.imgfahmi {
  position: absolute;
  top: 29px;

  left: 0;
  right: 0;

  width:422px;
  margin-left: auto;
  margin-right: auto;
  z-index: 99;
}

.bg-grid {
  position: absolute;
  bottom: 17px;
  right: 8%;
  width: 100px;
  z-index: 1000;
}

.text {
  position: absolute;
  display: flex;
  bottom: 97px;
  right: -55px;
  transform: rotate(90deg);
  z-index: 999;
}

.text h1 {
  font-size: 22px;
  font-weight: 200;
  color: #f5f7f9;
}
.text hr {
  width: 50px;
  border: 1px solid #f5f7f9;
  margin-left: 40px;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .section {
    height: 780px;
  }
  .intro {
    top: 120px;
    left: 40px;
  }
  .intro span {
    font-size: 38px;
    line-height: 44px;
    margin-top: 0px;
  }
  .intro span h1 {
    font-size: 38px;
  }
  .intro h2 {
    font-size: 14px;
    margin-top: 20px;
  }

  .intro hr {
    width: 105px;
    margin: 20px 0;
  }

  .shape {
    height: 640px;
  }

  .blue {
    height: 730px;
    clip-path: polygon(0 44%, 100% 35%, 100% 100%, 0% 100%);
    background-color: #2c5364;
    z-index: -1;
  }

  .yellow {
    height: 760px;
    clip-path: polygon(0 74%, 100% 63%, 100% 100%, 0% 100%);
    background-color: #c79e5a;
    margin-top: -733px;
    z-index: 9999;
  }

  .imgfahmi {
    top: 205px;
    width: 235px;
    overflow: hidden;
    z-index: 0;
  }

  .programs {
    top: 720px;
    left: 25px;
    padding: 8px;
    border-left: 0;
  }

  .bg-grid {
    right: 20px;
    bottom: -98px;
  }

  .text {
    display: none;
  }
}
@media screen and (min-width: 769px) and (max-width: 800px) {
  .programs {
    right:7%;
    top:67%
      }
      .programs-btn{
        font-size: 12px;
      }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .imgfahmi {
    top: 137px;
    width: 350px;
    overflow: hidden;
   
  }
  .navb img{
    height:40px;
  }
  .section {
    height: 640px;
  }
  .intro {
    top: 145px;
    left: 47px;
  }
   .intro span {
    font-size: 51px;
    line-height: 44px;
    margin-top: 0px;
  }
  .intro span h1 {
    font-size: 52px;
  }
  .intro h2 {
    font-size: 19px;
    margin-top: 103px;
  }
  .intro hr{
    width:107px
  }
  .programs{
    border:none;
    padding:0px;
  }
  .bg-grid {
  width:75px
  }
  .text{
    right:-76px
  }
  .text h1{
    font-size: 20px;
  }

  .programs {
right:9%;
top:67%
  }
  .programs-btn{
    font-size: 14px;
  }

}
@media screen and (min-width: 851px) and (max-width: 1200px) {


  .programs {
right:15%;
top:72%
  }

}