.section-elements {
  display: grid;
  grid-template-columns: 40% 60%;
  height: 600px;
  position: relative;
  overflow: hidden;
}
.title {
  display: inline-block;
  color: #ffffff;
  border-bottom: 15px solid #c79f5a;
  line-height: 0;
  padding: 0px 4%;
}
.contact {
  background-color: #2b2e35;
  height: 700px;
  grid-template-columns: 50% 50%;
  grid-template-rows: 60% 40%;
  grid-template-areas:
    "contact-description form"
    "social-media-section form";
  position: relative;
}
.contact-description {
  width: 70%;
  margin: auto;
  grid-area: contact-description;
}
.element-contact p {
  width: 70%;
  margin: 3% 0;
}

.element-contact {

  padding-top: 10%;
}

.form {
  background-color: #2c5364;
  padding: 50px;
  grid-area: form;
}

.contact-form-title {

  color: #ffffff;
}
.contact-form {
  width: 90%;
  margin: auto;
}
.social-media-section {
  background: rgba(199, 158, 90, 0.05);
  grid-area: social-media-section;
}
.social-media {
  width: 70%;
  margin: auto;
  padding-top: 50px;
}
.social-media-icons {
  display: flex;
  justify-content: space-between;
  width: 20%;
  align-items: center;
}

* {
  padding: 0;
  margin: 0;
}
.third {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.submit-form {
  width: 200px;
  color: white;
  background-color: #c79f5a;

  height: 52px;
  padding: 0;
  margin: 0px !important;
  margin-top: 20px !important;
}

.contacticon {

  position: absolute;
  left: 46%;
  top: 40%;
}
.social-media-icons img {
  width: 40px;
}

/* title */

.contact-us {
  color: white;
  width: 70%;
  margin: auto;
  position: relative;
}
.contact-us p {
  color: #aac2ce;

  width: 80%;
}
.MuiInput-custom {
  width: 100% !important;
}

@media (max-width: 576px) {
  .submit-form {
    margin-top: 0px;
    margin: 0px !important;
  }
  .css-10dqh8c-MuiFormControl-root{
    width: 27ch !important;
  }
  .contact {
    background-color: #2b2e35;
    height: 857px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 23% 62% 15%;
    grid-template-areas:
      "contact-description"
      "form"
      "social-media-section ";
    position: relative;
  }

  .contacticon {
    display: none;
  }
  .react-icons {
    font-size: 20px;
  }
  li {
    font-size: 14px;
  }
  .element-contact p {
    font-size: 14px;
  }
  .form {
    background-color: #2b2e35;
    padding: 10px ;
    grid-area: form;
  }
  .contact-form-title {
    text-align: center;
    display: none;
  }
  .submit-form {
    width: 100px;
    height: 40px;
  }
  .social-media {
    padding-top: 10px;
  }

  .contact-description {
    width: 70%;
    margin: auto;
  }
  .social-media-icons img {
    width: 35px;
  }
  .element-contact p {
    width: 100%;
  }
  .social-media-icons {
    width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .submit-form {
    margin-top: 0px;
    margin: 0px !important;
  }
  .contact {
    background-color: #2b2e35;
    height: 857px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 23% 62% 15%;
    grid-template-areas:
      "contact-description"
      "form"
      "social-media-section ";
    position: relative;
  }

  .contacticon {
    display: none;
  }
  .react-icons {
    font-size: 20px;
  }
  li {
    font-size: 14px;
  }
  .element-contact p {
    font-size: 14px;
  }
  .form {
    background-color: #2b2e35;
    padding: 10px 40px;
    grid-area: form;
  }
  .contact-form-title {
    text-align: center;
    display: none;
  }
  .submit-form {
    width: 100px;
    height: 40px;
  }
  .social-media {
    padding-top: 10px;
  }
  .social-media-icons {
    width: 80%;
    margin: auto;
  }
  .contact-description {
    width: 70%;
    margin: auto;
  }
  .social-media-icons img {
    width: 35px;
  }
  .element-contact p {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .social-media-icons img {
    width: 35px;
  }
  .contacticon {
    left: 46%;
    height: 170px;
    top: 45%;
  }
  .form {
    padding: 20px;
  }
  .contact-form-title {
    padding: 0px 10px;
  }

}
