.certifications {
  padding: 10% 20%;
  grid-area: certifications;
  font-size: 20px;
  text-align: justify;
  hyphens: auto;
}
.title-aboutme {
  margin-top: -50px;
  margin-bottom: 30px;
  display: inline-block;
  color: #ffffff;
  border-bottom: 15px solid #c79f5a;
  line-height: 0;
  padding: 0px 4%;
}

.certifications p:first-of-type:first-line {
  font-weight: bold;
}
ttzz {
  color: #c79e5a;
  font-size: 52px;
  display: inline-block;
  font-weight: 600;
}
.about {
  height: 800px;
  position: relative;
}
.section-elements-about {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-areas:
    "myPhoto"
    "certifications";
  height: 628px;
  position: relative;
  overflow: hidden;
}
.pattern-circle {
  position: absolute;
  top: 49%;
  left: -22%;
  width: 200px;
}
.pattern-circle-black {
  position: absolute;
  opacity: 40%;
  left: 14%;
  width: 200px;
}
.pattern-circle-bleu {
  position: absolute;
  opacity: 40%;
  left: 94%;
  top: 25%;
  width: 250px;
}
.myPhoto {
  grid-area: myPhoto;
  mix-blend-mode: lighten;
  background-image: url("../images/myPhoto.png");
  /* background-size: cover; */
  background-size: contain;
  z-index: 20;
  position: relative;
  width: 100%;
  height: auto;
  object-fit: cover;
  background-repeat: no-repeat;
}
@media (max-width: 576px) {
  .section-elements-about {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: auto auto;
    height: auto;
    position: relative;
    overflow: hidden;
    grid-template-areas:
      "certifications"
      "myPhoto";
  }
  .title-aboutme {
    display: inline-block;
    color: #ffffff;
    border-bottom: 15px solid #c79f5a;
    line-height: 0;
    padding: 0px 20px;
    margin-bottom: 5px;
  }
  .myPhoto {
    mix-blend-mode: lighten;
    background-image: url("../images/myPhoto.png");

    z-index: 20;
    position: relative;
    width: 100%;
    height: 400px;
    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .pattern-circle-black {
    position: absolute;
    opacity: 40%;
    left: 33%;
    width: 150px;
    top: 55%;
  }
  .pattern-circle-bleu {
    position: absolute;
    opacity: 40%;
    left: 93%;
    top: 15%;
    width: 200px;
  }
  .pattern-circle {
    position: absolute;
    top: 47%;
    left: -30%;
    width: 150px;
  }
  .certifications {
    padding: 10%;
    font-size: 14px;
    text-align: justify;
    hyphens: auto;
  }
  ttzz {
    color: #c79e5a;
    font-size: 30px;
    display: inline-block;
    font-weight: 600;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .pattern-circle-black {
    width: 150px;
  }
  .pattern-circle-bleu {
    left: 94%;
    top: 45%;
    width: 150px;
  }
  .pattern-circle {
    top: 60%;
    left: -20%;
    width: 150px;
  }
  .title-aboutme {
    display: inline-block;
    color: #ffffff;
    border-bottom: 15px solid #c79f5a;
    line-height: 0;
    padding: 0px 4%;
  }
  .certifications {
    padding: 10%;
    font-size: 14px;
    text-align: justify;
    hyphens: auto;
  }
  ttzz {
    color: #c79e5a;
    font-size: 30px;
    display: inline-block;
    font-weight: 600;
  }
  .section-elements-about {
    height: auto;
  }
  .myPhoto {
    mix-blend-mode: lighten;
    background-image: url("../images/myPhoto2.png");

    z-index: 20;
    position: relative;
    object-fit: cover;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .pattern-circle-black {
    position: absolute;
    opacity: 40%;
    left: 18%;
    width: 250px;
  }
}
@media (min-width: 1200px) and (max-width: 1024px) {
.section-elements-about{
  height:583px
}
}


