.navbar-dreamhouse {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  z-index: 999999;
}

.navbar-dreamhouse-scrolled {
  position: fixed;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #2b2e35;
  font-size: 16px;
  box-shadow: 0px 0px 0px 3px rgba(170, 194, 206, 0.3);
  z-index: 999999;
}

.navb {
  position: sticky;
  display: flex;
  top: 0;
  width: 88vw;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  margin: auto;
  z-index: 10;
}

.logo {
  margin-right: 20px;
}

img {
  cursor: pointer;
}
.navbar-toggler {
  font-size: 2rem;
  border: none;
}

.navi {
  color: #cdd4de;
  text-decoration: none;
  padding: 20px;
  cursor: pointer;
}

.menu-bars {
  display: none;
  background-color: transparent;
  margin-left: 3rem;
  font-size: 1.6rem;
  border: 0;
  outline: 0;
}

.drawer {
  position: fixed;
  display: none;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  color: white;
  opacity: 0;
  transform: translateX(100%);
  transition: 0.5s ease;
}
.drawer-is-open {
  opacity: 1;
  transform: translateX(0);
}

.drawer ul {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  background-color: #2b2e35;
  padding: 0;
}

.drawer ul li {
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  list-style-type: none;
}
.drawer ul li:hover {
  background-color: rgba(51, 50, 50, 0.692);
}

a:hover {
  color: #c79f5a;
}

.active {
  color: #c79f5a;
}
/**css responsive navbar**/
@media screen and (max-width: 767px) {
  .navb .navi {
    display: none;
  }
  .menu-bars {
    display: block;
  }
  .drawer {
    display: block;
  }
  .navb img{
    height:30px;
  }
}
