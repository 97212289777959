.card {
  position: relative;
  display: flex;
  width: 750px;
  height: fit-content;
  flex-direction: column;
  background-color: #2c5364;
  border-radius: 8px;
}

.card img {
  width: 100%;
  height: 392px !important;
  border-radius: 8px 8px 0 0;
  object-fit: cover !important;
  object-position:top !important;
}

.card h2 {
  color: #f5f7f9;
  margin: 50px 30px 20px 30px;
  font-weight: 600;
}

.card p {
  width: 480px;
  color: #f5f7f9;
  margin: 0 30px 50px 30px;
  font-weight: 600;
  opacity: 0.7;
}

.position {
  position: absolute;
  bottom: 0;
  right: 30px;
  font-size: 160px;
  font-weight: 900;
  opacity: 0.2;
}

@media (max-width: 576px) {
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: bisque;
    width: 18rem;
    height: fit-content;
    padding: 0 2rem 2rem 2rem;
    border-radius: 10px;
  }
}
@media (min-width: 769px) and (max-width: 992px){
.card {
  position: relative;
  display: flex;
  width: 400px;
  height: fit-content;
  flex-direction: column;
  background-color: #2c5364;
  border-radius: 8px;
}
.card img {
  width: 100%;
  height: 360px;
  border-radius: 8px 8px 0 0;
  object-fit: cover !important;
}
.position {
  position: absolute;
  bottom: 0;
  right: 30px;
  font-size: 130px;
  font-weight: 900;
  opacity: 0.2;
}

.card p {
  width: 240px;
  color: #f5f7f9;
  margin: 0 30px 50px 30px;
  font-weight: 600;
  opacity: 0.7;

}

}

@media (min-width: 993px) and (max-width: 1200px){
  .card {
    position: relative;
    display: flex;
    width: 550px;
    height: fit-content;
    flex-direction: column;
    background-color: #2c5364;
    border-radius: 8px;
  }
  
  .card img {
    width: 100%;
    height: 245px;
    border-radius: 8px 8px 0 0;
    object-fit: cover !important;
  }
  
  .card h2 {
    color: #f5f7f9;
    margin: 24px 30px 20px 30px;
    font-weight: 600;
  }
  
  .card p {
    width: 400px;
    color: #f5f7f9;
    margin: 0 30px 30px 30px;
    font-weight: 600;
    opacity: 0.7;
  }
  .position {
    position: absolute;
    bottom: 0;
    right: 30px;
    font-size: 120px;
    font-weight: 900;
    opacity: 0.2;
  }
  }