/***** onlinecoaching *****/
.coming-soon{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* margin: auto; */
    margin: 50% 0px;
}
.onlinecoaching {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #2b2e35;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.program-title {
  display: inline-block;
  color: #ffffff;
  border-bottom: 15px solid #c79f5a;
  line-height: 0;
  padding: 0px ;
  margin-top: 50px;
}
/* 
.pattern {
  position: absolute;
  top: 90px;
  left: -4%;
  width: 200px;
} */

.program-page-design {
  position: absolute;
  background-color: #c79f5a;
  width: 87vw;
  height: 90vh;
  top: 180px;
}

.program-page {
  position: absolute;
  display: grid;
  width: 85vw;
  height: 80vh;
  top: -40px;
  background-color: #2c5364;
  grid-template-columns: 50% 50%;
  bottom: 0%;
}

ul {
  padding: 0;
}
.program-page-description h2 {
  color: white;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0px;
}
li {
  padding: 8px 0px;
  list-style: none;
}

.program-page-description {
  padding: 10%;
  background-color: #2c5364;
}
.programs-title {
  width: 100%;
  text-align: center;
}

.hideInput {
  display: none;
}
.image-onlinecoaching {
  background-image: url("./images/online-coaching.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
}
.image-diet{
  background-image: url("./images/diet.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
}

.image-oto{
  background-image: url("./images/oto-training.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
}
.image-guide-ebook{
  background-image: url("./images/ebook.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
}
.program-page-image {
  position: relative;
}
@media screen and (max-width: 650px) {
  .image{
    display: none;   
  }
  
  .program-page{
    display: block;
   
  }
  .onlinecoaching{
    margin-bottom: 5vw;
  }
  .css-28vykf-MuiFormControl-root{
    margin:8px 4px !important
  }
  .program-title{
    margin-top: 78px;
    
  }
  .logo {
    margin-right: 3px;
}
  .program-request{
    margin:0px;
  }
  .program-title{
    font-size: 24px;
  }
  .diet{
    font-size: 22px;
  }
  }
  .program-page-description{
    /* height: 104vh; */
    padding:4%;
  }
  .program-page-design {
    position: absolute;
    background-color: #c79f5a;
    width: 88vw;
    height: 77vh;
  }