p{
  font-size: 16px !important;
}
input[type=submit] {
  -webkit-border-radius:0px;
}

.section-elements-programs {
  position: relative;
  display: grid;
  height: 793px;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.pattern {
  position: absolute;
  top: 0;
  left: -5%;
  width: 200px;
}
.pattern-blue {
  position: absolute;
  right: -4%;
  bottom: 0;
  width: 200px;
}

.element {
  display: flex;
  width: 80%;
  flex-direction: column;
  margin-left: auto;
  gap: 12px;
}

.title-programs {
  display: inline-block;
  width: 70%;
  color: #ffffff;
  border-bottom: 15px solid #c79f5a;
  line-height: 0;
  padding: 0px 4%;
}

.btn-programs {
  color: white;
  background-color: #c79f5a;
  padding: 9px ;
  margin: 0;
  outline: none;
  border: none;
  min-width: 100%;
  min-height: 42px;
}

.our-programs {
  display: block;
}

.our-programs-responsive {
  display: none;
  height: 0;
}

@media (max-width: 576px) {
  .title-programs {
    width: 52%;

  }
  

  .section-elements-programs {
    display: flex;
  }
  .pattern {
    display: none;
    height: 0;
  }
  .pattern-blue {
    display: none;
    height: 0;
  }

  .element {
    position: absolute;
    display: flex;
    width: 90%;
    top: 180px;
    flex-direction: column;
    margin: 0 auto;
    gap: 8px;
  }

  .our-programs {
    display: none;
    max-height: 0;
  }
  .our-programs-responsive {
    width:100%;
    margin:auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    height: 320px;
    margin: 0 0 20px 0;
    gap: 10px;

  }

  .box-program {
    height: 152px;
    width: 140px;
    border: 4px solid #aac2ce;
    border-radius: 8px;
    cursor: pointer;
  }

  .box-program:hover {
    border: 4px solid #c79f5a;
  }

  .box-program img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .title-programs {
    width: 32%;

  }
  .section-elements-programs {
    display: flex;
  }
  .pattern {
    display: none;
    height: 0;
  }
  .pattern-blue {
    display: none;
    height: 0;
  }

  .element {
    position: absolute;
    display: flex;
    width: 90%;
    top: 180px;
    flex-direction: column;
    margin: 0 auto;
    gap: 8px;
  }

  .our-programs {
    display: none;
    max-height: 0;
  }
  

  .our-programs-responsive {
    width:100%;
    margin:auto;
    display: flex;
  flex-wrap: wrap;
  justify-content: center;
    height: 414px;
    margin: 0 0 20px 0;
    gap: 18px;

  }
.btn-booking{
  display: flex;
  justify-content: center;
}
.btn-booking button {

  width: 491px;
}
  .box-program {
    height: 205px;
    width: 241px;
    border: 4px solid #aac2ce;
    border-radius: 8px;
    cursor: pointer;
  }

  .box-program:hover {
    border: 4px solid #c79f5a;
  }

  .box-program img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

@media (min-width: 769px) and (max-width: 993px){
  .section-elements-programs {

    height: 530px;

}
.pattern {
  position: absolute;
  top: 11px;
  left: -5%;
  width: 134px;
}
.pattern-blue {
  position: absolute;
  right: -5%;
  bottom: 0;
  width: 134px;
}
}
@media (min-width: 993px) and (max-width: 1200px){
  .section-elements-programs {
    height: 580px;
}
.pattern {
  position: absolute;
  top: -20px;
  left: -6%;
  width: 160px;
}
.pattern-blue {
  position: absolute;
  right: -5%;
  bottom: 0;
  width: 160px;
}
}