@media (min-width: 360px) and (max-width: 576px)  {
  .MuiInputBase-root:before{
    border-bottom: 3px solid #c79e5a !important;
    width: 30px;
    margin-left: 20px;
  }
  .MuiInputBase-root:after{
    border-bottom: 3px solid #c79e5a !important;
    width: 100%;
    margin-left: 0px;
  }
    .css-b995z3-MuiFormControl-root {
      width: 8ch !important;
    }
    .card-responsive {
        display: block;
        width: 100%;
        background-color: transparent;
      }
      .submit-oto{
        width:80%
      }
      .contact-description{
        width:80%
      }
      .css-10dqh8c-MuiFormControl-root {
        width: 33ch !important;
    }
    .myPhoto{
        height: 445px;
    }
    .pattern-circle-black {
        top: 52%;
    }
    .pattern-circle {
        top: 60%;
    }
    .element{
        width:80%  
    }
    .box-program {

        width: 153px;}

        .back{
          position: relative;
    top: -23%;
    left: -17%;
        }
}

@media (min-width: 320px) and (max-width: 360px)  {
  .MuiInputBase-root:before{
    border-bottom: 3px solid #c79e5a !important;
    width: 25px;
    margin-left: 20px;
  }
  .MuiInputBase-root:after{
    border-bottom: 3px solid #c79e5a !important;
    width: 100%;
    margin-left: 0px;
  }
}
@media (max-width: 360px){
  
  .card-responsive {
    display: block;
    width: 80%;
    background-color: transparent;
  }
  
}
@media (min-width: 577px) and (max-width: 786px) {
  .css-b995z3-MuiFormControl-root {
    width: 20ch!important;
  }
  .css-uzy4fs{
    width: 20ch!important;
  }
}